selectBox = () => {
	$(document).on('click', '.js_selectbox__btn', function(){
		$('.js_selectbox__btn').parent().removeClass('active');
		$(this).parent().toggleClass('active');
	});
	clickOutside('active', '.js_selectbox__btn', '.js_select-box');
	$(document).on('click', '.js_selectbox__option', function(){
		$(this).parent().prev().text($(this).text()).attr('data-value', $(this).attr('value'));
		$(this).parent().next().removeClass('active');
		let valueOther = $(this).attr('value');
		if(valueOther === 'Others') {
			$('.input-product-interested').addClass('active');
		}
	});
};
selectBox();