const components = {
  footerMenu: () => import(/* webpackChunkName: "footer" */ '../components/Footer'),
  // propagationStopper: () => import(/* webpackChunkName: "propagation-stopper" */ '../components/PropagationStopper'),
  // modal: () => import(/* webpackChunkName: "modal" */ '../components/Modal'),
  // testAPI: () => import(/* webpackChunkName: "test-api" */ '../components/testAPI'),
  // popup: () => import(/* webpackChunkName: "popup" */ '../components/Popup'),
  // selectBox: () => import(/* webpackChunkName: "selectBox" */ '../components/SelectBox.js'),
  mainMenu: () => import(/* webpackChunkName: "mainMenu" */ '../components/MainMenu.js')
};

class ComponentLoader {
  // eslint-disable-next-line class-methods-use-this
  lazyLoadComponents(element) {
    const dataComponents = [...element.querySelectorAll('*[data-component]')];
    // Sorting by prioritized components
    dataComponents.sort((c1, c2) => {
      const dataComponent = c2.getAttribute('data-component');
      if (dataComponent && dataComponent.indexOf('registerAnalyticsData') >= 0) {
        return 1;
      }
      return -1;
    });

    for (let i = 0; i < dataComponents.length; i++) {
      const component = dataComponents[i];
      const componentNames = component.getAttribute('data-component').split(',');
      componentNames.forEach((item) => {
        try {
          const componentItem = components[item.trim()];
          if (!componentItem) {
            return;
          }

          componentItem().then(({ default: Instance }) => {
            // eslint-disable-next-line no-new
            new Instance(component);
          });
        } catch (err) {
          console.log('comp', item, err);
        }
      });
    }
  }
}

export default new ComponentLoader();
