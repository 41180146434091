// Require the polyfill before requiring any other modules.
//import '@babel/polyfill';
import 'intersection-observer';
//import 'url-search-params-polyfill';
import ComponentLoader from '../componentLoader/component-loader';
// import registerWindowOnScroll, { handleScroll } from '../utilities/scroll';
import lazyloadImg from '../utilities/lazyloadImg';
// import { containsMany, containsAny, removeElement } from '../utilities/dom';
// import closest from '../utilities/closest';
// import resizeWindow from '../utilities/getResizeWindow';
function init() {
  ComponentLoader.lazyLoadComponents(document);
  // window.addEventListener('scroll', handleScroll);
  // removeElement();
  // registerWindowOnScroll(() => debounceResponsiveImg(), 'debounceResponsiveImg');
  lazyloadImg.DoObserver();
  // closest();
  // containsMany();
  // containsAny();
  // resizeWindow();
  require('../utilities/clickOutSide.js');
};
init();

window.addEventListener('DOMContentLoaded', (event) => {
  require('../utilities/selectBox.js');
  // require('../../blocks/booking-consultation/booking-consultation');
  // require('../../blocks/your-booking/your-booking');
  // require('../../blocks/booking-demonstration/booking-demonstration');
  // require('../../blocks/booking-private-consultation/booking-private-consultation');
  // require('../../blocks/booking-details/booking-details');
  // require('../../blocks/landing-your-booking/landing-your-booking');
  require('../../blocks/menu-mobile/menu-mobile');
});