clickOutside = (method, box, targetElement) => {
    $('html').on("click", 'body', e => {
        var container = $(box);
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            switch (method) {
                case 'fade':
                    $(targetElement).stop().fadeOut(500);
                    break;
                case 'slide':
                    $(targetElement).stop().slideUp();
                    break;
                case 'active':
                    $(targetElement).stop().removeClass('active');
                    break;
            }
        }
    });
};